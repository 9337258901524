<template>
  <div id="digital-clinics-login" class="container login" :class="{layaWb: isLayaWellbeing()}">
    <div class="d-none d-md-flex row logo-wrap">
      <div class="laya-logo col-12 py-5 my-5" >
        <img src="https://www.layahealthcare.ie/media/layahealthcare/desktopsite/newhomepage/images/laya-logo-main.svg">
      </div>
    </div>
    <div class="row page-content">
      <div class="d-none d-md-flex col-md-6 img-part">
        <div class="login-bg d-flex justify-content-center align-items-center">
          <div class="laya-image"></div>
        </div>
      </div>
      <div class="col-12 col-md-6 pl-md-0 card min-height">
        <div class="card-content px-md-5">
          <div class="mobile-logo d-flex d-md-none">
            <div class="logo-wrap">
              <img src="/img/laya/laya-logo-mobile.png">
            </div>
          </div>
          <h2 class="card-title brand-highlight-1 pt-5 pb-4 text-center">
            Laya Healthcare Digital Wellbeing
          </h2>
          <p class="laya-info px-md-5 pb-5">
            Please login to access laya healthcare's Digital Wellbeing Experts such as as Physiotherapists, Nutritionists, Healthcoaches and more.
            Visit Digital Studio to access our Wellbeing Series & Digital Gym.
          </p>
          <h4>{{screen === 0 ? 'Login to your laya member area' : 'Login as a Spectrum.life user'}}</h4>
        </div>
        <div class="button-wrap pb-5" >
          <b-button @click="trackClicks" class="layaBtn" :href="ssoRedirectLink">Login</b-button>
        </div>
        <div class="not-laya-member pb-5">
          <a @click="$emit('spectrum-login')">I'm not a Laya Member</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  //import { setOptions, bootstrap } from 'vue-gtag'

  export default {
    name: "DigitalClinicsLogin",
    data(){
      return{
        screen: 0
      }
    },
    mounted() {
      this.initGoogleAnalytics()
      //this.enablePlugin()
      this.track()
    },
    methods: {
      initGoogleAnalytics(){
        if(!document.getElementById('gtag-script')){
          let script = document.createElement('script')
          script.type = 'text/javascript'
          script.src = 'https://www.googletagmanager.com/gtag/js?id=' + process.env.VUE_APP_GA_TRACKING_ID
          script.id = 'gtag-script'
          script.async = true
          document.head.appendChild(script)
          window.dataLayer = window.dataLayer || [];
          this.gtag('js', new Date());
          this.gtag('config', process.env.VUE_APP_GA_TRACKING_ID);
        }
      },
      gtag(){
        window.dataLayer.push(arguments);
      },
      /*
       * changed on 27/10/19 to facilitate sanusX
      enablePlugin () {
        setOptions({
          config: { id: process.env.VUE_APP_GA_TRACKING_ID },
          params: {
            send_page_view: false
          }
        })
        bootstrap().then(() => {
          // all done!
        })
      }, */
      track() {
        this.gtag('pageview', {
          page_path: '/login',
        })
      },
      trackClicks() {
          this.gtag('event', 'click', {
            'event_category': 'Login',
                  'event_label': 'Login user click',
                  'value': 0
        })
      }
    },
    computed: {
      ssoRedirectLink() {
        if(process.env.NODE_ENV === 'production') {
          return 'https://www.layahealthcare.ie/memberarea/#/healthcoach/fwd?redir=https://api.aluna.app/application/api-hc/laya-clinic-sso'
        } else {
          return 'https://preprod.layahealthcare.ie/memberarea/#/healthcoach/fwd?redir=https://api.surge-qa.com/application/api-hc/laya-clinic-sso'
        }
      }
    }
  };
</script>

<style scoped lang="less">
#digital-clinics-login {
  .logo-wrap {
    justify-content: center;
    .laya-logo {
      display: flex;
      justify-content: center;
      img {
        max-width: 230px;
      }
    }
  }
  .page-content {
      max-width: 960px;
    width: 100%;
    background-color: #fff;
    border-radius: 30px;
    overflow: hidden;
    min-height: 400px;
    padding: 0px !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    margin: auto;
      .button-wrap{
          display: flex;
          justify-content: center;
          .cta-button{
              width: 180px;
          }
      }
    .img-part {
      padding-left: 0px;
      background: #014d71;
      background-size: cover;
      background-position: bottom;
      .login-bg {
        position: relative;
        height: 100%;
        width: 100%;
        .laya-image {
          height: 280px;
          width: 280px;
          background-image: url("/img/laya-wellness-login.jpg");
          background-color: #014d71;
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          border-radius: 50%;
          border-width: 3px;
          border-color: #fff;
          border-style: solid;
        }
      }
    }
    .card {
      background: #ffffff;
      .card-content {
        padding: 25px;
        min-heigh: 795px;
        text-align: left;
        h2 {
          color: #d60570;
            font-family: ff-cocon-web-pro;
            font-size: 2rem;
        }
        p {
          font-size: 1.4rem;
          font-family: "Open Sans", sans-serif;
          text-align: left;
        }
        h4{
          text-align: center;
          color: #d60570;
        }
        .mobile-logo{
          justify-content: center;
          .logo-wrap {
            max-width: 200px;
            img {
              width: 100%;
            }
          }
        }
      }
      .not-laya-member{
        width: 100%;
        text-align: center;
        a{
          color: #444444;
          font-size: 1.4rem;
          text-decoration: underline;
          font-family: "Open Sans", sans-serif;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    padding: 0;
    border: none;
    box-shadow: none;
    .page-content{
      border-radius: 0;
      box-shadow: none ;
      border: none;
      margin: auto;
      outline: none;
      .card{
        border: none;
      }
    }

  }
  &.layaWb {
    .card-title {
      font-size: 2.5rem;
    }
    .laya-info {
      text-align: center !important;

    }
    h4 {
      font-size: 2.2rem !important;
    }
  }
  .not-laya-member {
    a {
      text-decoration: underline !important;
    }
  }
  @media screen and (max-width: 768px) {
    &.layaWb {
      h2 {
        word-wrap: break-word;
        box-sizing: border-box;
        margin-top: 0;
        font-weight: 500;
        line-height: 1.2;
        padding-bottom: 1.5rem!important;
        padding-top: 3rem!important;
        text-align: center;
        margin-bottom: 20px !important;
        color: #d60570 !important;
        font-size: 2rem;
      }
      .laya-info {
        font-weight: 400;
        line-height: 1.5;
        word-wrap: break-word;
        box-sizing: border-box;
        margin-top: 0;
        margin-bottom: 1rem;
        padding-bottom: 3rem!important;
        color: #444!important;
        font-size: 1.4rem;
        font-family: Open Sans,sans-serif !important;
        text-align: left !important;
      }
      h4 {
        word-wrap: break-word;
        box-sizing: border-box;
        margin-top: 0;
        margin-bottom: .5rem;
        font-weight: 500;
        line-height: 1.2;
        font-size: 1.5rem !important;
        font-family: ff-cocon-web-pro,sans-serif;
        letter-spacing: 0!important;
        text-align: center;
        color: #d60570;
      }
      .layaBtn {
        word-wrap: break-word;
        box-sizing: border-box;
        text-decoration: none;
        display: inline-block;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid transparent;
        padding: .375rem .75rem;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        border-color: #6c757d;
        padding-top: 1rem!important;
        padding-bottom: 1rem!important;
        background: #d60570;
        font-family: ff-cocon-web-pro,sans-serif;
        letter-spacing: 0!important;
        color: #fff;
        cursor: pointer;
        border-radius: 60px;
        font-size: 1.6rem;
        line-height: 1;
        border-width: 0;
        width: 180px;
      }
    }
  }
}
</style>
