<template>
  <div id="NewLayaTopNav">
    <div id="navApp">
      <header id="navAppHeader" class="shadow-1">
        <div id="navHolder">
          <div class="help-link-holder d-flex align-items-center" @mouseover="dropdownOpen = false" @click="settingsOpen = false">
<!--            <div class="navIcon reminderNavIcon" @mouseleave="remindersExtended = false" :class="remindersExtended ? 'extended': null" tabindex="0">-->
<!--              <i @click="toggleRemindersExtended" :class="iconHoveredOver === 3? 'fa-solid' : 'fa-regular'" class="fa-bell navIcon fa-xl" @mouseover="iconHoveredOver = 3" @mouseleave="iconHoveredOver = -1"></i>-->
<!--              <div class="bookingAlert" v-if="unseenNotifications"></div>-->
<!--              <div v-if="unseenNotifications" class="bookingAlertBox">-->
<!--                <div class="bookingAlertBoxOuter" @mouseover="iconHoveredOver = 3" @mouseleave="iconHoveredOver = -1" v-if="!remindersExtended">-->
<!--                  <div class="bookingAlertBoxTriangle"></div>-->
<!--                  <div class="bookingAlertBoxInner">-->
<!--                    <i class="fa-regular fa-circle-exclamation"></i>-->
<!--                    Click the bell icon to see notifications-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="iconDropDown reminderDropDown">-->
<!--                <ReminderHolder :notifications="notifications" @close="remindersExtended = false"/>-->
<!--              </div>-->
<!--            </div >-->
<!--            <div class="navIcon apptNavIcon" @mouseleave="bookingsExtended = false" :class="bookingsExtended ? 'extended': null" tabindex="0" @keydown.enter="toggleBookingsExtended()">-->
<!--              <i :class="iconHoveredOver === 2? 'fa-solid' : 'fa-regular'" class="fa-calendar-check navIcon fa-xl"-->
<!--                 @mouseover="iconHoveredOver = 2" @mouseleave="iconHoveredOver = -1" @click="toggleBookingsExtended()" >-->
<!--              </i>-->
<!--              <div class="bookingAlert" v-if="showReminderAlert > 0"></div>-->
<!--              <div v-if="showReminderAlert > 0" class="bookingAlertBox">-->
<!--                <div class="bookingAlertBoxOuter" @mouseover="iconHoveredOver = 6" @mouseleave="iconHoveredOver = -1">-->
<!--                  <div class="bookingAlertBoxTriangle"></div>-->
<!--                  <div class="bookingAlertBoxInner">-->
<!--                    <i class="fa-regular fa-circle-exclamation"></i>-->
<!--                    {{ showReminderAlert > 1 ? 'You have bookings today, click here to view details' : 'You have a booking today, click here to view details' }}-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="iconDropDown apptHolder">-->
<!--                <NavAppointmentHolder :bookings="bookings" @reload="getBookings" @close="bookingsExtended = false"/>-->
<!--              </div>-->
<!--            </div>-->

            <div class="d-flex align-items-center ml-4 settingBtn position-relative" tabindex="0" @keydown.enter="openSettings">
              <div class="d-flex align-items-center settingToggleHolder px-2">
                <div @click.stop="openSettings" id="Nav_fullname">{{ name }}</div>
                <div class="nameTag settingToggle mr-3" style="" @click.stop="openSettings">
                  {{initals}}
                </div>
                 <i class="fa-solid" :class="settingsOpen ? 'fa-chevron-up' : 'fa-chevron-down'" @click.stop="openSettings"></i>
              </div>

              <div class="settingDropdown" :class="{open: settingsOpen}" @blur="settingsOpen = false" v-if="settingsOpen">
                <div class="settingDropdownItem" @click.stop="settingsClick">
                  <div class="topItem">
                    <div class="nameTag">
                      {{initals}}
                    </div>
                    <div>
                      <div class="fullName">
                        {{name}}
                      </div>
                      <div class="email">
                        {{ $store.getters.user.user.email }}
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="false" tabindex="0" class="settingDropdownItem otherItem" id="nav_dropdown" @click.stop="(e)=>{navigateFromFixedButton(e,'/my-progress')}" @keydown.enter="(e)=>{navigateFromFixedButton(e,'/my-progress')}">
                  <i class="fa-solid fa-chart-simple fa-lg mr-4 ml-2" id="dropdown-my-progress-icon"></i>
                  My Progress
                </div>
                <div tabindex="0" class="settingDropdownItem otherItem" id="nav_dropdown" style="border-top: 1px rgba(54, 56, 58, 0.25) solid;" @click.stop="(e)=>{navigateFromFixedButton(e,'/my-list')}" @keydown.enter="(e)=>{navigateFromFixedButton(e,'/my-list')}">
                  <i class="fa-solid fa-list fa-lg mr-4 ml-2" id="dropdown-watch-later-icon"></i>
                  Watch Later
                </div>
                <div tabindex="0" class="settingDropdownItem otherItem" id="nav_dropdown" @click.stop="(e)=>{navigateFromFixedButton(e,'/favourites')}" @keydown.enter="(e)=>{navigateFromFixedButton(e,'/favourites')}">
                  <i class="fa-regular fa-heart fa-lg mr-4 ml-2" id="dropdown-favourites-icon"></i>
                  Favourites
                </div>
<!--                <div class="settingDropdownItem otherItem"  @click="navigateFromFixedButton('/settings')">-->
<!--                  <i class="fa-solid fa-circle-user fa-lg mr-4 ml-2"></i>-->
<!--                  Settings-->
<!--                </div>-->
                <div style="border-top: 1px rgba(54, 56, 58, 0.25) solid;" class="settingDropdownItem otherItem logOut" id="nav_dropdown" @click="logOut" tabindex="0" @keydown.enter="logOut" @mouseover="logoutHover = true" @mouseleave="logoutHover = false">
                  <svg width="18" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-4 ml-2" id="dropdown-logout-icon">
                    <path id="dropdown-logout-icon" d="M6.40833 10.9917L7.58333 12.1667L11.75 8L7.58333 3.83333L6.40833 5.00833L8.55833 7.16667H0.5V8.83333H8.55833L6.40833 10.9917ZM13.8333 0.5H2.16667C1.24167 0.5 0.5 1.25 0.5 2.16667V5.5H2.16667V2.16667H13.8333V13.8333H2.16667V10.5H0.5V13.8333C0.5 14.75 1.24167 15.5 2.16667 15.5H13.8333C14.75 15.5 15.5 14.75 15.5 13.8333V2.16667C15.5 1.25 14.75 0.5 13.8333 0.5Z" :fill="'#DA1984'"/>
                  </svg>
                  Logout
                </div>
              </div>
            </div>
          </div>

          <nav class="laya-nav" role="navigation" @click="settingsOpen = false">
            <div class="nav-inner d-flex">
              <a aria-label="Laya Logo" id="logoLink" href="" @click="clickHomeBtn" @focus="settingsOpen = false"><div id="logoDiv"></div></a>
              <template v-for="item in nav">
                <div class="laya-nav-item" tabindex="0" v-if="item.hasOwnProperty('sub_items')" @mouseover="toggleDropdown(item)" :class="{active: item.id === selected_item}" @focus="toggleDropdown(item)">{{item.label}}</div>
                <div class="laya-nav-item" tabindex="0" v-else @click="navigate(item)" @keydown.enter="navigate(item)" @mouseover="dropdownOpen = false" @focus="dropdownOpen = false">{{item.label}}</div>
                <div class="laya-dropdown" :class="{closed: !dropdownOpen || item.id !== selected_item}">
                  <div v-for="item in sub_items" class="subItemContainer" @click="navigate(item)" @keydown.enter="navigate(item)" tabindex="0">
                    <div class="title">{{item.label}}</div>
                    <div class="description">{{item.description}}</div>
                  </div>
                </div>
              </template>
            </div>
            <div style="margin-left: auto" @mouseover="dropdownOpen = false">
<!--              <LayaSearchBar />-->
            </div>
          </nav>
        </div>
      </header>
    </div>
  </div>
</template>

<script>

import LayaSearchBar from "./LayaSearchBar.vue";
import ReminderHolder from "@/components/ReminderHolder.vue";
import EventBus from "@/helpers/eventbus";
import NavAppointmentHolder from "@/components/NavAppointmentHolder.vue";
import moment from "moment/moment";
import {mapGetters} from "vuex";
export default {
  name: "NewLayaTopNav",
  components: {NavAppointmentHolder, ReminderHolder, LayaSearchBar},
  data() {
    return {
      dropdownOpen: false,
      selected_item: 0,
      settingsOpen: false,
      remindersExtended: false,
      iconHoveredOver: -1,
      unseenNotifications: false,
      notifications: [],
      bookings: [],
      bookingsLoading: false,
      bookingsExtended: false,
      notificationChecker: null,
      logoutHover: false
    }
  },
  mounted() {
    this.getBookings();
    this.checkForNotifications();
    const _t = this;
    EventBus.$on('updateDFDReminders',function() {
      setTimeout(()=> {_t.getBookings();},5000);
    });
    this.$store.dispatch('setMyList');
    if(this.$route.name === 'challenges') {
      this.showChallenges = true
    }
     this.notificationChecker = setInterval(()=> {
      if(!this.unseenNotifications) this.checkForNotifications();
    },1000 * 60);
    const content = document.getElementById('content');
    content.addEventListener('mouseover',()=> {
      this.dropdownOpen = false;
      this.settingsOpen = false;
    })
  },
  destroyed() {
    clearInterval(this.notificationChecker);
  },
  computed: {
    ...mapGetters(['bookingReminderLastSeen']),
    showReminderAlert() {
      const today = moment().format("YYYY-MM-DD");
      if(today === this.bookingReminderLastSeen) return 0;
      let show = 0;
      this.bookings.map(item => {
        if(moment(item.start).format("YYYY-MM-DD") === today) show++;
      });
      return show;
    },
    initals() {
      return this.$store.getters.user.user.first_name.charAt(0).toUpperCase() + this.$store.getters.user.user.last_name.charAt(0).toUpperCase()
    },
    name() {
      return this.$store.getters.user.user.first_name +" "+ this.$store.getters.user.user.last_name;
    },
    activeSlug() {
      return this.$route.name
    },
    nav() {
      return this.$store.getters.nav;
    },
    sub_items() {
      const item = this.nav.filter(item => item.id === this.selected_item)[0];
      if(!item) return null;
      if(item.hasOwnProperty('sub_items')) {
        return item.sub_items;
      } else {
        return null;
      }
    }
  },
  methods: {
    settingsClick(evt) {
      evt.stopPropagation();
    },
    openSettings(evt) {
      evt.stopPropagation();
      this.settingsOpen = !this.settingsOpen;
    },
    clickHomeBtn(e) {
      e.preventDefault();
      this.$router.push('/');
    },
    toggleBookingsExtended() {
      if(this.showReminderAlert) this.$store.dispatch("setBookingReminderLastSeen");
      this.bookingsExtended = !this.bookingsExtended;
    },
    async checkForNotifications() {
      return;
      const res = await this.api({
        path: '/api/u/notifications/unread'
      })
      if(res.success) {
        this.unseenNotifications = res.unread_count && res.unread_count > 0;
      } else {
        this.unseenNotifications = false;
      }
    },
    async getBookings(){
      try {
        let res = await this.api({
          path: "api/u/home-page/get-booking-widget",
        });
        if (res.success) {
          this.bookings = res.bookings;
          EventBus.$emit("setBookingReminders", this.bookings);

        }
        this.bookingsLoading = false;
      } catch (e) {
        this.bookingsLoading = false;
      }

    },
    toggleRemindersExtended() {
      this.remindersExtended = !this.remindersExtended;
      if(this.remindersExtended) {
        EventBus.$emit('getReminders');
        this.unseenNotifications = false;
      }
    },
    toggleDropdown(item) {
      if(!this.dropdownOpen) {
        this.dropdownOpen = true;
        this.selected_item = item.id;
      } else if(this.selected_item !== item.id) {
        this.dropdownOpen = true;
        this.selected_item = item.id;
      }
    },
    navigateFromFixedButton(e, url) {
      e.stopPropagation();
      this.sideNavExpanded = false;
      this.$router.push(url)
    },
    logOut() {
      this.$store.dispatch("logout");
      this.$router.push("/login").catch(err => {});
    },
    navigate(item) {
      this.dropdownOpen = false;
      if (!item.external_link) {
        if(item.asset_id){
          this.$router.push({ name: item.item_slug, params: { id: item.asset_id }  });
        }else{
          this.$router.push({ name: item.item_slug });
        }
      } else {
        window.open("https://" + item.external_link, "_blank");
      }
    }
  },
  watch: {
    iconHoveredOver(val) {
      //if(val) this.settingsOpen = false;
    },
    dropdownOpen(newVal) {
      if(!newVal) this.selected_item = 0;
    }
  }
}
</script>

<style lang="less">
#NewLayaTopNav {
  .settingBtn {
    cursor: pointer;
  }
  #HelpLink {
    color: var(--laya-heading-color);
    &:hover {
      color: var(--laya-pink-500);
    }
  }
  .resultsBackground {
    max-width: 540px;
  }
  .help-link-holder {
    padding-top: calc(2 * 1.2rem);
    padding-right: calc(2.5 * 1.6rem);
    padding-bottom: calc(1.5 * 1.2rem);
    -webkit-justify-content: flex-end;
    -webkit-align-items: flex-end;
    display: flex;
  }
  .settingToggleHolder {
    padding: 4px !important;
    border-radius: 10px;
    &:hover {
      background-color:#FAFAFA;
    }
  }
  .nameTag {
    margin-right: 0.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
    width: calc(4rem - 6px);
    height: calc(4rem - 6px);
    background-color: #da1984;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .settingDropdown {
    cursor: pointer;
    .settingsDropdownItem {
      width: 100%;
      padding: 8px 15px;
      i {
        margin-right: 20px;
        color: #0d77a1;
      }
      &:hover {
        background-color: #f5f5f5;
      }
    }
    min-width: 250px;
    width: fit-content;
    position: absolute;
    right: 0;
    top: 42px;
    height: 0;
    min-height: 0;
    z-index: 981;
    background-color: white;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    border-radius: 10px;
    transition: all 0.3s linear;
    overflow: hidden;
    .settingDropdownItem {
      width: 100%;
      padding: 10px 12px 10px 12px;
      font-size: 16px;
      //border-bottom: 1px rgba(54, 56, 58, 0.25) solid;
      min-height: 55px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-family: "Open Sans",sans-serif;
      &.logOut {
        color: #DA1984;
      }
      &:last-of-type {
        border-bottom: none;
      }
      &.otherItem {
        &:hover {
          color: #d60570;
          i {
            color: #d60570;
          }
        }
      }
    }
    .topItem {
      font-family: "Cocon Pro Regular",sans-serif;
      display: grid;
      grid-template-columns: 2fr 8fr;
      .nameTag {
        grid-column: 1;
        grid-row: 1 / span 2;
      }
      .fullName {
        text-align: left;
        margin-left: 5px;
      }
      .email {
        font-size: 12px;
        font-family: "Open Sans",sans-serif;
        margin-left: 5px;
      }
    }

    &.open {
      transition: all 0.3s linear;
      min-height: 100px;
      height: fit-content;

    }
  }
  #navAppHeader {
    margin-bottom: 0px !important;
  }
  font-family: "Cocon Pro Regular";
  .search-button {
    margin-left: -4rem !important;
  }
  .laya-dropdown {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    .subItemContainer {
      cursor: pointer;
      width: 100%;
      height: 100%;
      padding: 16px;
      border-radius: 10px;
      .title {
        font-size: 16px;
      }
      .description {
        font-family: Open Sans, sans-serif;
        font-size: 14px;
      }
      &:hover {
        background-color: #FAFAFA;
      }
    }
  }
  .apptNavIcon, .reminderNavIcon {
    .bookingAlert {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 10px;
      height: 10px;
      background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
      border-radius: 50%;
      z-index: 201;
    }
    .bookingAlertBox {
      display: none;
      overflow: visible;
      position: absolute;
      z-index: 201;
      left: -150px;
      bottom: -70px;
      width: 300px;
      .bookingAlertBoxOuter {
        position: relative;
        .bookingAlertBoxTriangle {
          position: absolute;
          background-image: linear-gradient(90deg, #a73870 0%, #9c386e 100%);
          clip-path: polygon(50% 0, 100% 100%, 0 100%);
          width: 25px;
          height: 20px;
          top: -19px;
          left: 160px;
        }
      }
      .bookingAlertBoxInner {
        color: white;
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        overflow: visible;
        display: flex;
        align-items: center;
        font-size: 13px;
        border-radius: 5px;
        padding: 5px;
        i {
          color: white;
          margin-right: 10px;
          font-size: 2.3rem;
        }
      }
    }
    &:hover {
      .bookingAlertBox {
        display: block;
      }
    }
  }
  .navIcon {
    cursor: pointer;
    position: relative;
    margin: 0 5px;
    color: #9F9E93;
    &:hover, &.challengesOpen {
      color: #d63384;
    }

    .walletHolder {
      width: 400px;
      height: 200px;
      background-color: transparent;
      top: 20px;
      right: 0;
    }
    .apptHolder {
      width: 300px;
      background-color: white;
      top: 25px;
      right: -80px;
    }
    .reminderDropDown {
      background-color: white;
      top: 25px;
      right: -80px;
    }
    .iconDropDown {
      display: none;
      position: absolute;
      overflow: visible;
      z-index: 9999;

    }
    &.hoverable:hover, &.extended {
      .iconDropDown{
        display: block;
      }
    }
  }
  .navItems {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 14px;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    .topNavItem {
      width: 100%;
      max-width: 200px;
      position: relative;
      height: 100%;
      padding: 2px 3px;
      display: flex;
      text-align: center;
      align-items: center;
      justify-content: center;
      .navDropDown {
        display: none;
        width: 100%;
        position: absolute;
        overflow: visible;
        z-index: 9999;
        top: 50px;
        .navDropDownItem {
          padding: 2px 3px;
          width: 100%;
          color: white;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 0;
          &:hover {
            color: white;
            background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
          }
        }
      }
      &:hover {
        background: linear-gradient(90deg, #ED3A75 0%, #6A356A 100%);
        color: white;
        .navDropDown {
          display: flex;
          flex-direction: column;
        }
      }
      .navDropDown:hover {
        display: flex;
        flex-direction: column;
      }
    }
  }
  #navHolder {
    max-width: 100% !important;
  }
  #Notification {
    .iconHolder {
      i {
        font-size: 3rem;
      }
    }
    .new {
      font-size: 1.2rem !important;
    }
    .date {
      font-size: 1.15rem !important;
    }
    .body {
      font-size: 1.25rem !important;
    }
  }
}
</style>